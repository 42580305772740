'use client'
import React from 'react'

import PageBuilderWrapper from '../../page-builder/pageBuilder'

const CustomPage = ({ title, body, get_snel_naar, info_lists }) => {
  const intro_link = get_snel_naar && get_snel_naar[0] && get_snel_naar[0].value
  return (
    <PageBuilderWrapper
      body={body}
      link={intro_link}
      lists={info_lists}
      pageTitle={title}
      firstH1={false}
    />
  )
}

export default CustomPage
