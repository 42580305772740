'use client'
import React from 'react'
import styled from 'styled-components'

import * as importedSections from '../sections'
import BodyArticle from '../sections/bodyArticle'

export const sectionElementDict = {
  add_event: 'AddEvent',
  article: 'Article',
  colored_links: 'ColoredLinksSections',
  home_cta_section: 'CtaSection',
  filter_content: 'FilterContentSection',
  iframe: 'IframeSection',
  embed: 'IframeSection',
  info: 'Information',
  link_list_image: 'LinkListImageSection',
  location_info: 'LocationInfo',
  location_intro: 'LocationIntro',
  locations: 'Locations',
  locations_square: 'LocationSquareSection',
  mediatext: 'MediaTextSection',
  news: 'NewsHomeSection',
  openingschema: 'OpeningSchema',
  overview_intro: 'OverviewIntro',
  product_choice: 'ProductChoiceSection',
  providers: 'Providers',
  search: 'SearchSection',
  success: 'SuccessSection',
  text: 'TextBlock',
  upcoming: 'UpcomingEvents',
  upcoming_small: 'UpcomingEventsSmall',
  vimeo: 'VimeoSection',
  home_cta: 'HomeCtaSection',
  events: 'EventCalendarHome',
  search_cta: 'SkewedCta',
  links: 'LinkListImageSection',

  facilities: 'LocationInfo',
  section: 'BodyArticle',
  html: 'HtmlSection',
  news_small: 'NewsSection',
  colums: 'BodyListColumns',
  sublocations: 'LocationInfo',
  aanbieders: 'Providers',
  veldkeuring: 'PlayingFieldsSection',
  maps_locatie: 'StaticMapSection',
  sportlocaties: 'LocationSquareSection',
  prices: 'TableSection',
  form: 'FormBuilderSection',
  table: 'TableSection',
  faq: 'FaqSection',
}

const subsiteSectionElementDict = {
  section: 'SubsiteArticle',
  prices: '',
  contact: 'ContactSection',
  news: 'SubsiteShortNewsSection',
  tables: 'Tables',
  rates: 'Rates',
  share: '',
  form: 'FormBuilderSection',
  faq: 'FaqSection',
}

const getSectionElement = (type, isSubsite) => {
  const dict = isSubsite ? subsiteSectionElementDict : sectionElementDict
  return type in dict ? dict[type] : ''
}

const Wrapper = styled.div`
  padding-top: 80px;
  section {
    margin-bottom: 120px;

    @media screen and (max-width: 1000px) {
      margin-bottom: 40px;
      margin-top: 20px;
    }
  }
`

// This wraps the page builder and the page navigator
const PageBuilderWrapper = ({
  body,
  link,
  lists,
  isSubsite = false,
  pageTitle = '',
  firstH1 = true,
}) => {
  return (
    <Wrapper>
      {body &&
        body?.map((sectionData, index) => {
          const sectionType = getSectionElement(sectionData.type, isSubsite)
          const SectionElement = importedSections.default[sectionType]
          if (!SectionElement) return null
          if (index === 0 && sectionData.type === 'section') {
            // Add link / list to Section if it is the first element
            return (
              <section key={`section-element-${index}`}>
                <SectionElement
                  lists={lists}
                  link={link}
                  {...sectionData?.value}
                  sectionData={sectionData.value}
                  isFirst={index === 0 && firstH1}
                />
              </section>
            )
          } else if (index === 0 && (lists || link)) {
            // Add a bodyarticle with pagetitle as title when the
            // First element is not a Section
            return (
              <section key={`section-element-${index}`}>
                <BodyArticle
                  lists={lists}
                  link={link}
                  title={pageTitle}
                  firstH1={firstH1}
                />
                <SectionElement
                  {...sectionData?.value}
                  sectionData={sectionData.value}
                  index={index}
                  isFirst={false}
                />
              </section>
            )
          }
          return (
            <section key={`section-element-${index}`}>
              <SectionElement
                {...sectionData?.value}
                sectionData={sectionData.value}
                isFirst={index === 0 && firstH1}
              />
            </section>
          )
        })}
    </Wrapper>
  )
}
PageBuilderWrapper._defaultProps = {}
export default PageBuilderWrapper
